import { ReactNode, createContext, useCallback, useContext } from 'react';

import { inProductionEnvironment, stripSensitiveUrlParams } from '../helpers';
import config from '../../config';
import useGoogleAnalytics, { UseGoogleAnalytics } from '../useGoogleAnalytics';

interface AtletaTrackingProviderContextValue {
  track: (url: string) => void;
}

const AtletaTrackingProviderContext = createContext<AtletaTrackingProviderContextValue>(undefined);

interface AtletaTrackingProviderProps {
  googleAnalytics?: boolean;
  googleAnalyticsProps?: UseGoogleAnalytics;
  filterPath?: (url: string) => string;
  children?: ReactNode;
}

const AtletaTrackingProvider = ({
  googleAnalytics = false, googleAnalyticsProps = {}, filterPath = (url) => url, children,
}: AtletaTrackingProviderProps) => {
  const enable = inProductionEnvironment();

  const { trackPageView } = useGoogleAnalytics({
    enabled: !!(enable && googleAnalytics),
    propertyId: config.googleAnalyticsId,
    filterPath: (path) => filterPath(stripSensitiveUrlParams(path)),
    cookiePrefix: 'atleta', // Projects using cross-domain tracking shouldn't overwrite our cookie.
    ...googleAnalyticsProps,
  });

  const track = useCallback((path: string) => {
    trackPageView(`/atleta/${path}`);
  }, [trackPageView]);

  return (
    <AtletaTrackingProviderContext.Provider value={{ track }}>
      {children}
    </AtletaTrackingProviderContext.Provider>
  );
};

export const useAtletaTracking = () => useContext(AtletaTrackingProviderContext);

export default AtletaTrackingProvider;
